<template>
  <div class="luta_continua_background">
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div class="d-flex align-self-center justify-center align-center">
          <img
            width="40%"
            src="../../assets/conteudos_luta_continua_title.png"
            alt=""
          />
        </div>
      </v-row>
      <v-row class="d-flex justify-md-space-around align-center pt-5 pb-5 ovx">
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'conteudos_tematicos_luta_continua' }">
            Luta pelo reassentamento
          </router-link>
        </v-col>
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'conteudos_tematicos_luta_musica' }">
            Música A LUTA
          </router-link>
        </v-col>
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'conteudos_tematicos_luta_crime' }">
            Não prescrição do crime
          </router-link>
        </v-col>
        <v-col
          cols="4"
          md="2"
          class="d-flex justify-center align-center text-center"
        >
          <router-link :to="{ name: 'conteudos_tematicos_luta_reparacao' }">
            Reparação Integral
          </router-link>
        </v-col>
      </v-row>
    </section>
    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              class="sobre__projeto_card"
              title="“A gente só quer a vida da gente de volta”: a luta pelo direito ao reassentamento"
              borderColor="#426E51"
              titleColor="#426E51"
            >
              <template v-slot:conteudo>
                <p>
                  O rompimento da barragem de Fundão provocou a destruição dos
                  territórios de Paracatu de Baixo e Bento Rodrigues, em
                  Mariana, e Gesteira, em Barra Longa. O reassentamento das
                  comunidades arrasadas pela lama foi uma conquista dos
                  atingidos e atingidas, pois o Brasil não possui legislação
                  específica relativa à obrigatoriedade da restituição do
                  direito à moradia adequada pelos agentes causadores de danos.
                  Geralmente, os projetos de realocação são conduzidos pelos
                  responsáveis a partir das diretrizes estabelecidas pelo Banco
                  Mundial. Tais diretrizes, contudo, não contemplam as
                  territorialidades específicas das comunidades afetadas.
                </p>
                <p>
                  Dentre as diretrizes do Banco Mundial, está a diferenciação
                  entre deslocamento físico e deslocamento econômico.
                  Inicialmente, o reassentamento das comunidades atingidas em
                  Mariana foi pensado sob a ótica do deslocamento físico, sendo
                  elegíveis para a realocação apenas aqueles que perderam sua
                  moradia habitual. Porém, essa definição se mostrou bastante
                  limitada para abarcar o universo das afetações associadas ao
                  desastre. Submetidos a um complexo processo de mudança social
                  de caráter compulsório, as reivindicações dos atingidos e
                  atingidas revelam que a vivência do desastre implica
                  deslocamento compulsório, isolamento comunitário,
                  inabitabilidade em moradias provisórias, empobrecimento,
                  adoecimento, rupturas de laços sociais e impossibilidade de
                  manutenção dos métodos tradicionais de produção (<a
                    class="pink--text"
                    style="text-decoration: none;"
                    href="http://mg.caritas.org.br/storage/arquivo-de-biblioteca/June2021/MIwBtLN8CmICguyU7uxc.pdf"
                    target="_blank"
                    >CÁRITAS, 2021</a
                  >).
                </p>
                <p>
                  Desde o rompimento da barragem, as empresas responsáveis
                  (Samarco, Vale e BHP Billiton) utilizam estratégias que
                  resultam na fragmentação das comunidades. Em Paracatu de
                  Baixo, por exemplo, após um ano, a Samarco apresentou três
                  alternativas de terrenos para que os moradores pudessem
                  selecionar um deles para a reconstrução da comunidade. Diante
                  das opções apresentadas pela mineradora, os moradores
                  selecionaram o terreno conhecido por Lucila. Porém, com a
                  chegada da Assessoria Técnica, representada pela Cáritas, em
                  novembro de 2016, foi identificada a necessidade da ampliação
                  da área insuficiente para a relocação integral da comunidade.
                  Ademais, as empresas consideraram elegíveis para o
                  reassentamento comunitário somente os proprietários dos lotes
                  menores de Paracatu de Baixo, o que resultou na exclusão de
                  aproximadamente 20 famílias denominadas “sitiantes”, que
                  integravam os circuitos de troca e vizinhança de Paracatu.
                </p>
                <p>
                  Entretanto, além da área da Lucila, foi necessária a aquisição
                  de oito terrenos adicionais para contemplar as famílias que
                  haviam sido desconsideradas. A necessidade de ampliação da
                  área para serem realocados veio acompanhada de mais uma etapa
                  de negociação com os proprietários dos terrenos no entorno da
                  área designada Lucila, o que desencadeou um delongado processo
                  de compra e regularização fundiária somente finalizado em
                  abril de 2018 (LIMA, 2018; CÁRITAS, 2021).
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_luta_continua_01.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center;">
            Manifestação em frente ao Fórum de Mariana. Foto: Acervo Gesta,
            2017.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_luta_continua_02.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center;">
            Dia em que foi iniciada a construção da primeira casa no
            reassentamento de Paracatu de Baixo. Foto: Joice Valverde (Jornal A
            Sirene, 2021).
          </p>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card class="sobre__projeto_card" borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A restrita extensão original do terreno proposto para o
                  reassentamento não aponta para a existência de um mero erro
                  técnico. Ao contrário, a ventilação do terreno de Lucila como
                  destino possível e apropriado reflete as próprias concepções
                  dos agentes responsáveis entre aqueles que seriam reassentados
                  e outros que receberiam apenas indenizações.
                </p>
                <p>
                  A morosidade com que foi e ainda é conduzido o processo de
                  reassentamento coloca os atingidos e atingidas em uma posição
                  de frustração e incerteza duradoura quanto ao futuro. Foram
                  recorrentes as alterações dos prazos para a conclusão dos
                  reassentamentos e tais descumprimentos jamais foram
                  judicialmente penalizados com a aplicação de multas à Fundação
                  Renova. Em decorrência dos sucessivos atrasos, há uma contínua
                  preocupação quanto ao esvaziamento dos reassentamentos
                  coletivos. Os relatórios produzidos pela empresa Ramboll<sup
                    >1</sup
                  >
                  (2019, p. 26) indicam que “a desistência do reassentamento
                  coletivo pode comprometer o sucesso do empreendimento final”.
                  No relatório mensal, de abril de 2021, a Ramboll apontou que
                  das 149 famílias de Paracatu de Baixo, 29% desistiram do
                  reassentamento coletivo. Já em Bento Rodrigues, entre as 294
                  famílias, o índice de desistência foi de 22% e, em Gesteira,
                  de 62%. Tal situação revela um prognóstico de fragmentação
                  comunitária com a dissolução dos núcleos de vizinhança,
                  alterando, assim, a dinâmica dos locais.
                </p>
                <p>
                  Ainda é preciso destacar que os reassentamentos não respeitam
                  integralmente o
                  <a
                    class="pink--text"
                    style="text-decoration: none;"
                    href="https://conflitosambientaismg.lcc.ufmg.br/wp-content/uploads/2020/08/BOLETIM-16-PARACATU-VERSAO-DIGITAL-COMPRIMIDA-12.pdf"
                    target="_blank"
                    >modo de vida das comunidades</a
                  >
                  atingidas. Em Mariana, a relocação foi planejada sob a ótica
                  das legislações urbanísticas de uso e ocupação do solo do
                  município. A construção do “Novo Bento” e da “Nova Paracatu”
                  exigiu a regularização dos loteamentos através da criação de
                  dispositivos complementares ao Plano Diretor Urbano e
                  Ambiental de Mariana. Diante da reivindicação dos atingidos e
                  atingidas de Paracatu de Baixo para a preservação do seu modo
                  de vida, o reassentamento dessa comunidade foi projetado como
                  um sistema “misto”, com características rurais e urbanas. Para
                  tanto, foi proposto e aprovado o Projeto de Lei que
                  regulamenta a Área de Diretrizes Especiais de Paracatu de
                  Baixo (ADIES). A criação deste PL foi um marco importante na
                  luta pelo reassentamento, pois fixou o tamanho mínimo dos
                  lotes em 250 m<sup>2</sup>, tendo em vista os casos em que as
                  famílias viviam em imóveis alugados ou cedidos.
                </p>
                <p>
                  No entanto, para além do parcelamento do solo, há outras
                  questões que são objeto de preocupação da comunidade como a
                  cobrança prevista da água e outras tributações que, antes do
                  rompimento da barragem, não faziam parte das despesas das
                  famílias atingidas.
                </p>
                <p>
                  Entre os atingidos de Paracatu de Baixo, passados cinco anos
                  desde a escolha do terreno, há insegurança quanto à
                  disponibilidade de água bruta<sup>2</sup> e a responsabilidade
                  dos custos de manutenção do sistema de abastecimento no
                  reassentamento. A fartura e qualidade de água na antiga
                  Paracatu são recorrentemente lembradas. As águas que
                  abasteciam os moradores e permitiam seu uso para o plantio e o
                  manejo das criações eram provenientes das nascentes, dos
                  córregos e do próprio Rio Gualaxo do Norte. As águas das
                  nascentes eram geridas coletivamente através de um sistema
                  implantado e mantido pelos próprios moradores, sem a
                  necessidade de bombeamento ou tratamento químico. Nesse
                  contexto, há significativa incerteza quanto ao custo e à
                  viabilidade ambiental e econômica do modelo adotado para o
                  reassentamento. Quanto à indisponibilidade da água bruta no
                  terreno onde serão realocados, a Fundação Renova propôs
                  futuras compensações monetárias e de forma individualizada,
                  medida que não só prejudica a reprodução de práticas
                  produtivas, quanto compromete a autonomia comunitária na
                  gestão dos recursos naturais essenciais ao seu modo de vida.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card class="sobre__projeto_card" borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Em suma, as soluções empresariais propostas para a reparação
                  do direito à moradia estão em descompasso em relação à
                  territorialidade dos grupos. A indisponibilidade de água bruta
                  no reassentamento de Paracatu de Baixo ameaça a segurança
                  hídrica e também alimentar da comunidade.
                </p>
                <p>
                  Desde 2015, as pessoas atingidas das comunidades rurais de
                  Mariana lutam para a reparação integral dos danos sofridos e o
                  restabelecimento de suas condições de vida. Nesse período, a
                  Fundação Renova insiste em utilizar critérios de elegibilidade
                  ou prazos definidos unilateralmente a exemplo do
                  reconhecimento dos novos núcleos familiares constituídos.
                  Tendo em vista a duração prolongada da reparação do direito à
                  moradia e o desenvolvimento dos grupos domésticos com
                  processos presumíveis de recomposição familiar, as diretrizes
                  de reassentamento homologadas estabelecem a garantia de um
                  imóvel para cada novo núcleo formado. Inicialmente, a Fundação
                  Renova buscou estabelecer um marco temporal, que contemplava
                  somente novas famílias que se formaram até janeiro de 2019.
                  Porém, foi decidido em primeira instância que novos núcleos
                  formados até dezembro de 2020 têm garantido o direito à
                  moradia.
                </p>
                <p>
                  Segundo o relatório da Cáritas (2021), no processo de
                  cadastramento e a partir do diálogo com a comunidade atingida,
                  foram identificados até o momento 33 novos núcleos familiares
                  da comunidade de Paracatu de Baixo. Entretanto, o processo de
                  reconhecimento desses núcleos não ocorre de forma
                  transparente, pois a Fundação Renova mobiliza critérios de
                  elegibilidade unilateralmente definidos e não divulgados. As
                  famílias não admitidas por tais critérios obscuros e não
                  reconhecidas pela Fundação terão que enfrentar mais uma fase
                  de batalha judicial para o reconhecimento de seu direito ao
                  reassentamento.
                </p>
                <p>
                  Além da interrupção das dinâmicas territorializadas no antigo
                  território e a submissão ao tempo da reparação, a recusa da
                  Fundação Renova em cumprir com a decisão judicial quanto ao
                  reconhecimento dos novos núcleos pode comprometer o “horizonte
                  de gerações” que organiza o próprio patrimônio familiar
                  (WANDERLEY, 1996).
                </p>
                <p>
                  A morosidade que permeia todo o processo de reassentamento
                  ocasiona cansaço e um eterno sentimento de espera. A luta para
                  que o reassentamento seja o retorno da autonomia de conduzir a
                  própria vida, continua como “uma cessão resistida, batalhada e
                  negociada” (SCOTT, 2009, p. 10).
                </p>
                <p style="text-align: center;">
                  <strong>Autora: Maryellen Milena de Lima<sup>3</sup>.</strong>
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img
            max-width="100%"
            src="../../assets/conteudos_luta_continua_03.png"
            alt=""
          />
          <p style="font-size: 0.6em; text-align: center;">
            Manifestação em frente ao Fórum de Mariana. Foto: Jornal A Sirene,
            2017.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 section__mb">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <sup>1</sup> No âmbito do acordo extrajudicial denominado
              TAC-Governança, a empresa Ramboll foi contratada para atuar como
              perita do Ministério Público Federal, com o objetivo de realizar o
              monitoramento independente dos Programas de reparação e
              compensação dos danos socioeconômicos e socioambientais
              ocasionados pelo desastre. Com isso, a Ramboll vem produzindo uma
              série de relatórios com o levantamento de questões críticas ao
              longo da Bacia do Rio Doce.
            </p>
            <p>
              <sup>2</sup> Água bruta é aquela coletada diretamente dos
              córregos, rios e nascentes, sem necessidade de tratamentos
              químicos. Na antiga Paracatu de Baixo, os moradores utilizavam
              coletivamente estas águas que estavam dentro de seus próprios
              terrenos.
            </p>
            <p>
              <sup>3</sup> Pesquisadora GESTA/UFMG. Mestranda em Sociedade,
              Ambiente e Território (UFMG/Unimontes).
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 section__mb">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p><strong> Referências Bibliográficas </strong></p>
            <p>
              CÁRITAS BRASILEIRA REGIONAL MINAS GERAIS.
              <strong>Atrasos</strong>: Relatório de entrega dos
              reassentamentos, Mariana/MG, fevereiro de 2021. Disponível em:
              <a
                href="http://mg.caritas.org.br/storage/arquivo-de-biblioteca/June2021/MIwBtLN8CmICguyU7uxc.pdf"
                class="pink--text"
                style="text-decoration: none"
                >http://mg.caritas.org.br/storage/arquivo-de-biblioteca/June2021/MIwBtLN8CmICguyU7uxc.pdf</a
              >
              .
            </p>
            <p>
              LIMA, Maryellen Milena. “<strong>Aqui não é o nosso lugar</strong
              >”: Efeitos socioambientais, a vida provisória e o processo de
              (re)construção de Paracatu de Baixo, Mariana/MG. 116 f. Monografia
              (Graduação em Ciências Socioambientais) — Faculdade de Filosofia e
              Ciências Humanas da Universidade Federal de Minas Gerais, Minas
              Gerais, 2018. Disponível em:
              <a
                href="https://conflitosambientaismg.lcc.ufmg.br/wp-content/uploads/2019/06/MONOGRAFIA-Maryellen5.pdf"
                class="pink--text"
                style="text-decoration: none"
                >https://conflitosambientaismg.lcc.ufmg.br/wp-content/uploads/2019/06/MONOGRAFIA-Maryellen5.pdf</a
              >
              .
            </p>
            <p>
              RAMBOLL. <strong>Dossiê do Reassentamento</strong>, junho de 2019.
            </p>
            <p>
              RAMBOLL. <strong>Relatório de Monitoramento Mensal</strong>. Mês
              052, Abril/2021.
            </p>
            <p>
              SCOTT, Parry.
              <strong>Negociações e Resistências Persistentes</strong>
              Agricultores e a barragem de Itaparica num contexto de descaso
              planejado. Recife: Editora Universitária UFPE, 2009.
            </p>
            <p>
              VALENCIO, Norma. Vivência de um Desastre: uma análise sociológica
              das dimensões políticas e psicossociais envolvidas no colapso de
              barragens. In: VALENCIO, Norma. et al. (org.)
              <strong>Sociologia dos Desastres</strong> construção, interfaces e
              perspectivas no Brasil. São Carlos: RiMA Editora, 2009.
            </p>
            <p>
              WANDERLEY, Maria de Nazaré Baudel. Raízes históricas do
              campesinato brasileiro.
              <strong>XX Encontro Anual da ANPOCS</strong>, GT 17, Processos
              Sociais Agrários. Caxambu, MG., outubro de 1996.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="section__mb">
      <v-row class="d-flex justify-center align-center pa-4 section__mb">
        <v-col class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_luta_musica' }"
            color="#EDBD43"
          >
            <strong>Conhecer "Música A LUTA"</strong>
          </v-btn>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "A Luta Continua /",
          href: "conteudos_tematicos_luta_continua",
        },
        {
          text: `${this.$route.meta.title}`,
          href: `${this.$route.name}`,
        },
      ],
    };
  },
};
</script>

<style scoped>
.luta_continua_background {
  background-image: url("../../assets/conteudos_luta_continua_background.png");
  background-size: cover;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f9f7ed;
}

a {
  color: #426e51;
  font-size: 0.8em;
}
.router-link-exact-active {
  cursor: pointer;
  text-decoration: underline;
}

.router-link {
  color: #426e51;
}

.section__mb {
  padding-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

@media (max-width: 600px) {
  .ovx {
    overflow-y: scroll;
    flex-wrap: nowrap;
    overflow-y: auto;

    margin-bottom: 2em;
  }

  .ovx::-webkit-scrollbar {
    width: 10px;
  }

  .ovx::-webkit-scrollbar-track {
    box-shadow: inset 0 1px #426e51;
    border: 6px solid transparent;
    background-clip: content-box;
    width: 180px;
  }
  .ovx::-webkit-scrollbar-thumb {
    background: #426e51;
    border-radius: 10px;
    height: 4px;
    width: 180px;
  }
}
</style>
